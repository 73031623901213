<template>
  <div class="check">
    <div class="title">
      <div class="title_left">
        <div class="flex">
          <div class="flexBox">
            <div class="flexTitle">权限</div>
            <el-input v-model="search_param.auth_name"></el-input>
          </div>
          <div class="flexBox">
            <div class="flexTitle">状态</div>
            <el-select
              v-model="search_param.status"
              placeholder="全部状态"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in auditOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="flexBox" style="flex: 2; margin-right: 80px">
            <div class="flexTitle" style="width: 110px">登录日期</div>
            <el-date-picker
              v-model="search_param.login_time"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="title_right">
        <el-button
          v-debounce
          v-disabled="{ name: 'list_user' }"
          type="primary"
          style="margin-left: auto"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 250px)"
      @sort-change="tableSort"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange1"
      @row-click="rowClick"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="权限" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.auth_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.admin_name || "暂无" }}</div>
        </template>
        <template v-slot:header>
          <div style="display: flex; align-items: center">
            <div>姓名</div>
            <div class="tableHeader">
              <el-popover
                @hide="onHide"
                placement="bottom-end"
                :width="300"
                trigger="click"
              >
                <template #reference>
                  <el-icon color="#1890ff" size="18"><Search /></el-icon>
                </template>
                <div style="display: flex">
                  <el-input
                    v-model="search_param.admin_name"
                    clearable
                  ></el-input>
                  <el-button
                    v-debounce
                    type="primary"
                    @click="getList({ cP: 1, size: pageSize })"
                    style="margin-left: 10px"
                    >搜索</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="登录名" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.account || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="手机" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.iphone || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.status || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        min-width="120px"
        sortable="custom"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.create_time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="最近登录时间"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.last_login_time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="客户数" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.customer_num || "暂无" }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>姓名：{{ selectItem.admin_name }}</span>
          </span>
          <span style="margin: 0 30px">登录名: {{ selectItem.auth_name }}</span>
          <span>状态：{{ selectItem.status }}</span>
        </div>
        <el-divider />
        <Detail :auId="auId" />
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { list_user } from "@/api/bank/rights.js";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import Detail from "./Detail";
import { Search } from "@element-plus/icons-vue";

//查询参数对象
const search_param = reactive({
  auth_name: "",
  admin_name: "",
  status: "",
  login_time: [],
  order_by_create_time: "",
  page: 1,
  size: 10,
});

// 重置功能
const reset = () => {
  (search_param.auth_name = ""),
    (search_param.admin_name = ""),
    (search_param.status = ""),
    (search_param.login_time = []),
    (search_param.order_by_create_time = null);
};

//审核状态选择下拉框信息
const auditOptions = ref([
  { value: 1, label: "正常" },
  { value: 2, label: "禁用" },
  // { value: 3, label: "到期" },
  // { value: 4, label: "注销" },
]);

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection1 = ref([]);
const handleSelectionChange1 = (val) => {
  multipleSelection1.value = val;
};
//获得数据列表
const getList = ({ cP = 1, size = 10, tips = false }) => {
  currentPage.value = cP;
  pageSize.value = size;
  list_user({
    auth_name: search_param.auth_name,
    admin_name: search_param.admin_name,
    status: search_param.status,
    login_time: search_param.login_time,
    order_by_create_time: search_param.order_by_create_time,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
    totalCount.value = res.data.count;
  });
};

const tableSort = (column) => {
  switch (column.order) {
    case "ascending":
      search_param.order_by_create_time = 2;
      break;
    case "descending":
      search_param.order_by_create_time = 1;
      break;
    case null:
      search_param.order_by_create_time = null;
      break;
    default:
      break;
  }
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

const showDrawer = ref(false);
let auId = ref("");
let selectItem = ref({});
const rowClick = async (row) => {
  showDrawer.value = true;
  auId.value = row.id;
  selectItem.value = row;
};

const onHide = () => {
  search_param.admin_name = "";
};

const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.title {
  display: flex;
}
.title_left {
  flex: 1;
}
.title_right {
  float: right;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 80px;
  text-align: center;
}
.tableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
