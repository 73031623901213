<template>
    <div style="margin:0 10px">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="用户列表" name="first">
                <UserList />
            </el-tab-pane>
            <el-tab-pane label="创建用户" name="second" :disabled="!Auth.includes('add_user')">
                <UserCreate />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    name: "importUserList",
};
</script>


<script setup>
import { ref } from 'vue'
import UserList from './components/UserList'
import UserCreate from './components/UserCreate'
import { useCache } from '@/hooks/useCache'

const activeName = ref('first')
const { wsCache } = useCache()
const Auth = wsCache.get('Auth')
</script>

<style scoped>
:deep(.el-tabs__nav-wrap::after){
    background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}
</style>

