<template>
  <div class="create">
    <div style="flex: 1;max-width: 500px">
      <el-form
        :label-position="labelPosition"
        label-width="100px"
        model="right"
      >
        <el-form-item label="登录账号">
          <el-input v-model="formData.account" placeholder="仅支持大小写英文" clearable />
        </el-form-item>
        <el-form-item label="权限">
          <el-select v-model="formData.au_id" placeholder="权限" style="width: 100%" clearable>
            <el-option v-for="item in auditOptions" :key="item.au_id" :label="item.auth_name" :value="item.au_id" />
          </el-select>
        </el-form-item>
        <el-form-item label="有效期">
          <el-input v-model="formData.expiration_time" type="number" clearable>
            <template #append>
                天
              </template>
          </el-input>
        </el-form-item>
        <el-form-item label="*姓名">
          <el-input v-model="formData.admin_name" clearable />
        </el-form-item>
        <el-form-item label="部门" style="display:flex">
          <el-input v-model="formData.network" placeholder="网点" style="flex:1;margin-right:5px" clearable/>
          <el-input v-model="formData.department" placeholder="部门" style="flex:1" clearable/>
        </el-form-item>
        <el-form-item label="*手机">
          <el-input v-model="formData.phone" clearable />
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="formData.email" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div style="flex: 1;padding:5px 20px">
      (创建成功后，初始密码为“账号+ @ +手机号后四位”)
    </div>
    <div style="width: 150px">
      <el-button v-debounce type="primary" style="margin-left: auto;" @click="AddUser">提交</el-button>
      <el-button v-debounce @click="reset">重置</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { add_user, list_character } from '@/api/bank/rights.js'
import { ElMessage } from 'element-plus'


const formData = ref({
  account: '',
  au_id: '',
  expiration_time: '',
  admin_name: '',
  network: '',
  department: '',
  phone: '',
  email: ''
})

// 重置功能
const reset = () => Object.keys(formData.value).map(item => formData.value[item] = '')

const AddUser = () => {
  add_user({
    account: formData.value.account,
    au_id: formData.value.au_id,
    expiration_time: Number(formData.value.expiration_time)*86400,
    admin_name: formData.value.admin_name,
    network: formData.value.network,
    department: formData.value.department,
    phone: formData.value.phone,
    email: formData.value.email
  })
  .then(res => {
    ElMessage({
      grouping: true, 
      showClose: true,
      message: res.msg,
      type: 'success',
    })
    reset()
  })
}

//审核状态选择下拉框信息
const auditOptions = ref([])
const getList = () => {
    list_character()
    .then(res => {
      auditOptions.value = res.data.list
    })
}

onMounted(() => {
  getList()
})
</script>

<style scoped>
.create{
  display:flex;
  margin-top: 10px;
}
</style>