<template>
  <div class="create">
    <div style="flex: 1; max-width: 500px">
      <el-form :label-position="labelPosition" label-width="80px" model="right">
        <el-form-item label="登录账号">
          <el-input
            v-model="formData.account"
            placeholder="仅支持大小写英文"
            disabled
            clearable
          />
        </el-form-item>
        <el-form-item label="权限">
          <el-select
            v-model="formData.au_id"
            :disabled="!Auth.includes('update_user')"
            placeholder="权限"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in auditOptions"
              :key="item.au_id"
              :label="item.auth_name"
              :value="item.au_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="有效期">
          <el-input v-model="formData.expiration_time" disabled clearable />
        </el-form-item>
        <el-form-item label="*姓名">
          <el-input v-model="formData.admin_name" disabled clearable />
        </el-form-item>
        <el-form-item label="部门" style="display: flex">
          <el-input
            v-model="formData.network"
            placeholder="网点"
            style="flex: 1; margin-right: 5px"
            clearable
          />
          <el-input
            v-model="formData.department"
            placeholder="部门"
            style="flex: 1"
            clearable
          />
        </el-form-item>
        <el-form-item label="*手机">
          <el-input v-model="formData.phone" clearable />
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="formData.email" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div style="flex: 1; padding: 0 20px">
      <el-button
        v-debounce
        @click="initPass"
        v-disabled="{ name: 'initialize_password' }"
        >初始化密码</el-button
      >
    </div>
    <div style="width: 400px; text-align: right">
      <el-button
        v-debounce
        v-disabled="{ name: 'update_user' }"
        type="primary"
        style="margin-left: auto"
        @click="updateInfo"
        >更新信息</el-button
      >
      <el-button v-debounce v-disabled="{ name: 'ban_user' }" @click="banUser"
        >禁用</el-button
      >
      <el-button v-debounce v-disabled="{ name: 'ban_user' }" @click="unBanUser"
        >解禁</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from "vue";
import {
  user_info,
  init_password,
  update_user,
  ban_user,
  unban_user,
  list_character,
} from "@/api/bank/rights.js";
import { ElMessage } from "element-plus";
import { useCache } from "@/hooks/useCache";

const { wsCache } = useCache();
const Auth = wsCache.get("Auth");

const props = defineProps(["auId"]);

const formData = ref({
  account: "",
  au_id: "",
  expiration_time: "",
  admin_name: "",
  network: "",
  department: "",
  phone: "",
  email: "",
});

const getDetail = async () => {
  user_info({
    id: Number(props.auId),
  }).then((res) => (formData.value = res.data.info));
};

const initPass = () => {
  init_password({
    id: Number(props.auId),
  }).then((res) =>
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    })
  );
};

const banUser = () => {
  ban_user({
    id: Number(props.auId),
  }).then((res) =>
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    })
  );
};

const unBanUser = () => {
  unban_user({
    id: Number(props.auId),
  }).then((res) =>
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    })
  );
};

const updateInfo = async () => {
  let res = await update_user({
    id: Number(props.auId),
    network: formData.value.network,
    department: formData.value.department,
    phone: formData.value.phone,
    email: formData.value.email,
    au_id: formData.value.au_id || "",
  });
  if (res.code == 200) {
    await getDetail();
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  }
};

//审核状态选择下拉框信息
const auditOptions = ref([]);
const getList = () => {
  list_character().then((res) => {
    auditOptions.value = res.data.list;
  });
};

watch(
  () => props.auId,
  (newVal) => {
    if (newVal != "") {
      getDetail();
    }
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  getList();
});
</script>

<style scoped>
.create {
  display: flex;
  margin-top: 35px;
}
</style>
